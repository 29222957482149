import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import BgBanner from "../../images/press/BgBanner.jpg";

function Innerbanner(props) {
    return (
        <div className="press_innerbanner" style={{backgroundImage: `url(${BgBanner})`}}>
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={10} lg={10} xl={6} xxl={6} className="banner_content m-auto text-center">
                        <span>Seascape</span>
                        <h4>Richird Norton photorealistic rendering as real photos</h4>
                        <p>Progressively incentivize cooperative systems through technically sound functionalities. The
                            credibly productivate seamless data.</p>
                        <div className='date'>08.08.2021</div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Innerbanner;